import type {Action} from "~/flux/ActionTypes"
import {Logger} from "~/lib/Logger"

const logger = new Logger("Dispatcher")

class Dispatcher {
  private static instance: Dispatcher
  private listeners: Array<(action: Action) => void> = []
  private isDispatching = false
  private isDebugLoggingEnabled = false
  private actionQueue: Array<Action> = []

  private constructor() {
    this.isDebugLoggingEnabled = window.ENV.NODE_ENV === "development"
  }

  /**
   * Get the singleton instance of the Dispatcher.
   * @returns The Dispatcher instance.
   */
  static getInstance(): Dispatcher {
    if (!Dispatcher.instance) {
      Dispatcher.instance = new Dispatcher()
    }
    return Dispatcher.instance
  }

  /**
   * Register a listener for dispatched actions.
   * @param listener The function to call when an action is dispatched.
   */
  register(listener: (action: Action) => void): void {
    this.listeners.push(listener)
  }

  /**
   * Dispatch an action to all registered listeners.
   * @param action The action to dispatch.
   */
  dispatch(action: Action): void {
    if (this.isDispatching) {
      this.actionQueue.push(action)
      return
    }

    this.isDispatching = true
    const debugLoggingEnabled = this.isDebugLoggingEnabled
    if (debugLoggingEnabled) {
      logger.debug(`Dispatching action: ${action.type}`)
    }

    const startTime = Date.now()
    try {
      for (const listener of this.listeners) {
        listener(action)
      }
    } finally {
      this.isDispatching = false
      const endTime = Date.now()
      const timeTaken = endTime - startTime
      if (timeTaken > 100) {
        logger.warn(`Slow dispatch: ${action.type} took ${timeTaken}ms`)
      }
      this.processQueue(debugLoggingEnabled)
    }
  }

  /**
   * Process the action queue, dispatching any actions that were queued while a
   * dispatch was in progress.
   */
  private processQueue(debugLoggingEnabled: boolean): void {
    while (this.actionQueue.length > 0) {
      const queuedActions = [...this.actionQueue]
      this.actionQueue = []
      for (const nextAction of queuedActions) {
        if (debugLoggingEnabled) {
          logger.debug(`Dispatching queued action: ${nextAction.type}`)
        }
        this.dispatch(nextAction)
      }
    }
  }
}

export default Dispatcher.getInstance()
