const STYLE = `
	font-weight: bold;
	color: purple;
`

type LogLevel = "log" | "info" | "warn" | "error" | "trace" | "debug"

export class Logger {
  private name: string

  constructor(name = "default") {
    this.name = name
  }

  info(...args: Array<any>): void {
    this.log("info", ...args)
  }

  warn(...args: Array<any>): void {
    this.log("warn", ...args)
  }

  error(...args: Array<any>): void {
    this.log("error", ...args)
  }

  trace(...args: Array<any>): void {
    this.log("trace", ...args)
  }

  debug(...args: Array<any>): void {
    this.log("debug", ...args)
  }

  private log(level: LogLevel, ...args: Array<any>): void {
    console[level](`%c[${this.name}]`, STYLE, ...args)
  }
}
