import React from "react"
import type {Action} from "~/flux/ActionTypes"
import Dispatcher from "~/flux/Dispatcher"

export type StateListener<T> = (state: T) => void
export type StateUpdater<T> = (prevState: T) => T

export abstract class Store<T> {
  private listeners: Array<StateListener<T>> = []
  protected state: T

  constructor(initialState: T) {
    this.state = initialState
    Dispatcher.register(this.handleAction.bind(this))
  }

  /**
   * Subscribe to state changes.
   * @param listener The function to call when the state changes.
   * @returns A function to unsubscribe from state changes.
   */
  subscribe(listener: StateListener<T>): () => void {
    this.listeners.push(listener)
    return () => this.unsubscribe(listener)
  }

  /**
   * Unsubscribe from state changes.
   * @param listener The function to remove from the listeners.
   */
  private unsubscribe(listener: StateListener<T>): void {
    this.listeners = this.listeners.filter((l) => l !== listener)
  }

  /**
   * Add a change listener to the store.
   * @param listener The function to call when the state changes.
   */
  addChangeListener(listener: StateListener<T>): void {
    this.subscribe(listener)
  }

  /**
   * Remove a change listener from the store.
   * @param listener The function to remove from the listeners.
   */
  removeChangeListener(listener: StateListener<T>): void {
    this.unsubscribe(listener)
  }

  /**
   * Set the new state and notify listeners.
   * @param newState The new state or a function that returns the new state
   * based on the previous state.
   */
  protected setState(newState: T | StateUpdater<T>): void {
    this.state = typeof newState === "function" ? (newState as StateUpdater<T>)(this.state) : newState
    this.notifyListeners()
  }

  /**
   * Notify all subscribed listeners of state changes.
   */
  private notifyListeners(): void {
    for (const listener of this.listeners) {
      listener(this.state)
    }
  }

  /**
   * Get the current state.
   * @returns The current state.
   */
  getState(): T {
    return this.state
  }

  /**
   * Hook to use the store's state in a React component.
   * @returns The current state.
   */
  useStore(): T {
    return React.useSyncExternalStore(
      (callback) => {
        const unsubscribe = this.subscribe(callback)
        return () => unsubscribe()
      },
      () => this.getState(),
    )
  }

  /**
   * Abstract method to handle actions. Must be implemented by subclasses.
   * @param action The action to handle.
   */
  abstract handleAction(action: Action): void

  /**
   * Sync with stores and invoke a handler when all Stores processed.
   * @param stores The stores to sync with.
   * @param callback The callback to invoke.
   * @param timeout The debounce timeout.
   */
  syncWith(stores: Array<Store<any>>, callback: () => any, timeout = 0): void {
    const wrapper = debounce(timeout, () => {
      if (callback() !== false) {
        this.notifyListeners()
      }
    })
    for (const store of stores) {
      store.subscribe(wrapper)
    }
  }
}

/**
 * Debounce function to limit the rate at which a function can fire.
 * @param delay The debounce delay in milliseconds.
 * @param callback The callback to debounce.
 * @returns A debounced function.
 */
const debounce = (delay: number, callback: (...args: Array<any>) => void): ((...args: Array<any>) => void) => {
  let timerId: NodeJS.Timeout | null = null
  return (...args: Array<any>) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => callback(...args), delay)
  }
}
