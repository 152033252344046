import {Logger} from "~/lib/Logger"
import {NAVIGATE_EVENT_BUS} from "~/lib/NavigateListener"

const logger = new Logger("RouterUtils")

export const transitionTo = (path: string) => {
  logger.info("transitionTo", path)
  NAVIGATE_EVENT_BUS.emit("navigate", {path, replace: false})
}

export const replaceWith = (path: string) => {
  logger.info("replaceWith", path)
  NAVIGATE_EVENT_BUS.emit("navigate", {path, replace: true})
}
